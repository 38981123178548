import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { UpperFirstPipe } from '@lancelot-frontend/core';
import { TProvider } from '../auth-providers.component';

@Component({
  selector: 'ffb-auth-provider-button',
  templateUrl: './auth-provider-button.component.html',
  styleUrls: ['./auth-provider-button.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, TuiButtonModule, TuiSvgModule, UpperFirstPipe],
})
export class AuthProviderButtonComponent {
  @Input() provider!: TProvider;
  @Input() disabled? = false;
  @Input() showLoader? = false;
}
