import { Component, inject } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { AuthProviderButtonComponent } from '../auth-provider-button/auth-provider-button.component';
import { TProvider } from '../auth-providers.component';

@Component({
  selector: 'ffb-auth-providers-link-credentials-dialog',
  templateUrl: './link-credentials-dialog.component.html',
  styleUrls: ['./link-credentials-dialog.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, TuiButtonModule, AuthProviderButtonComponent],
})
export class LinkCredentialsDialogComponent {
  private readonly context =
    inject<
      TuiDialogContext<
        TProvider | null,
        { email: string; providers: TProvider[] }
      >
    >(POLYMORPHEUS_CONTEXT);

  providers = this.context.data.providers;
  email = this.context.data.email;

  proceedWith(provider: TProvider) {
    this.context.completeWith(provider);
  }

  cancel() {
    this.context.completeWith(null);
  }
}
