<ng-container *transloco="let t">
  <button
    tuiButton
    size="m"
    appearance="outline"
    class="full-width lowercase"
    [disabled]="disabled"
    [showLoader]="showLoader"
  >
    <div class="p2 absolute left-0">
      <tui-svg src="/assets/icon-set/{{ provider }}.svg"></tui-svg>
    </div>
    {{ t('auth.signInWith', { provider: provider | upperfirst }) }}
  </button>
</ng-container>
